import React, { useState, useEffect } from 'react';
import './furniRating.css';
import starImage from '../images/icons/rating_icon.gif';

function FurniRating({ rating }) {
    const [stars, setStars] = useState([])

    const generateStars = () => {
        let stars = []
        if (rating > 5 || rating < 1) {
            return;
        }
        for (let i = 0; i < rating; i++) {
            stars.push(i);
        }
        return stars;
    };

    useEffect(() => {
        setStars(generateStars());
    }, []);

    return (
        <div className="furniRating">
            {stars.map((star, index) => (
                // <i key={index} className="bi bi-star-fill"></i>
                <img key={index} src={starImage} alt='Rating' className="star-img" />
            ))}
        </div>
    );
}

export default FurniRating;

