// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
// BS Icons
import 'bootstrap-icons/font/bootstrap-icons.css';
// Console Message
import consoleMessage from './utils/consoleMessage.js';

import React, { useState } from 'react';
import './App.css';
import Main from './pages/Main';

export const AppContext = React.createContext();

function App() {
  const [favorites, setFavorites] = useState([])
  const [bag, setBag] = useState([])

  console.log(consoleMessage);

  return (
    <>
      <AppContext.Provider value={{ favorites, setFavorites, bag, setBag }}>
        <Main />
      </AppContext.Provider>
    </>
  );
}

export default App;
