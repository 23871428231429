import React, { useState, useEffect } from 'react';
import './bag.css';
import FurniBagItem from '../components/FurniBagItem';
import clubsofa from '../images/icons/cs_icon.png';

function Bag({ furnis, reference }) {
  const [total, setTotal] = useState(0);

  const handleTotalPayment = () => {
    return furnis
      .map(furni => furni.price * (1 - furni.discount))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(2)
  };

  useEffect(()=>{
    setTotal(handleTotalPayment());
  },[furnis]);

  return (
    <section id="bag" className='bag' ref={reference}>
      <div className="container-fluid">
        <div className="row mb-3">
          <h1>My Bag</h1>
        </div>
      </div>
      {
        furnis.length === 0 ? (
          <h2>Your Bag is empty</h2>
        ) : (
          <>
            <div className="row">
              <div className="table-responsive">
                <table className="furniBagTable table table-borderless align-middle">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Preview</th>
                      <th scope="col">Furni</th>
                      <th scope="col">Price</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Payment</th>
                      <th scope="col">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {furnis.map((furni, index) => (
                      <FurniBagItem
                        index={index}
                        key={`${furni._id}-${index}`}
                        furni={furni}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row d-flex justify-content-between mt-5">
              <div className="col-lg-2 d-flex align-items-center">
                <p className="itemCount">Total Items: {furnis.length}</p>
              </div>
              <div className="col-10-lg d-flex justify-content-end">
                <div className="payment">
                  Total: {total}
                  <img src={clubsofa} alt="Club Sofa" className='cs-icon' />
                  <a href="#">
                    Check out <i className="bi bi-wallet2"></i>
                  </a>
                </div>
              </div>
            </div>
          </>
        )
      }
    </section>
  );
}

export default Bag;