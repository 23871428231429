import React, { useState, useEffect } from 'react';
import './timeDisplay.css'; // Ensure correct import path

const TimeDisplay = () => {
    const [timeData, setTimeData] = useState(null);
    const apiUrl = 'https://origins.habbo.com/api/public/info/time';

    useEffect(() => {
        // Function to fetch time data
        const fetchTimeData = () => {
            fetch(apiUrl)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setTimeData(data))
                .catch(error => console.error('Error fetching data:', error));
        };

        // Fetch time data initially when component mounts
        fetchTimeData();

        // Set interval to update time data every second
        const intervalId = setInterval(fetchTimeData, 1000); // 1000 milliseconds = 1 second

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.getFullYear();
        const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });

        return `${day} ${month}, ${year}, ${time}`;
    };

    return (
        <div className="time-container">
            {timeData ? (
                <p className="lead">
                    {formatTime(timeData.time)}
                </p>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default TimeDisplay;
