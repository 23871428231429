const filterListData = [
    {
        _id: 1,
        name: 'All',
        active: true,
    },
    {
        _id: 2,
        name: 'HC',
        active: false,
    },
    {
        _id: 3,
        name: 'Rare',
        active: false,
    },
    {
        _id: 4,
        name: 'Funky',
        active: false,
    },
    
];

export default filterListData;