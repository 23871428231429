import React, { useContext } from 'react';
import './furniBagItem.css';
import { AppContext } from '../App'; // Adjust import to match your setup

function FurniBagItem({ furni, index }) {
    const { bag, setBag } = useContext(AppContext);

    const handleRemoveFromBag = () => {
        // Remove the item at the given index
        setBag(bag.filter((_, i) => i !== index));
    };

    return (
        <tr className="furniBagItem">
            <th scope='row'>{index + 1}</th>
            <td>
                <img src={furni.img} alt={furni.title} className="img-fluid" />
            </td>
            <td>{furni.title}</td>
            <td>{furni.price.toFixed(2)}</td>
            <td>{(furni.discount * 100).toFixed(2)}%</td>
            <td>{(furni.price * (1 - furni.discount)).toFixed(2)}</td>
            <td>
                <a href="#" onClick={(e) => {
                    e.preventDefault(); // Prevent default anchor behavior
                    handleRemoveFromBag(); // Remove item at the index
                }}>
                    <i className="bi bi-trash"></i>
                </a>
            </td>
        </tr>
    );
}

export default FurniBagItem;
