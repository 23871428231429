import React, { useState, useEffect } from 'react';
import './usersOnline.css'; // Ensure the CSS file path is correct
import TimeDisplay from './TimeDisplay'; // Import TimeDisplay component

function UsersOnline() {
    const [onlineUsers, setOnlineUsers] = useState(null);
    const apiUrl = 'https://origins.habbo.com/api/public/origins/users';

    useEffect(() => {
        const fetchOnlineUsers = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch online users');
                }
                const data = await response.json();
                setOnlineUsers(data.onlineUsers); // Corrected the key name
            } catch (error) {
                console.error('Error fetching online users:', error);
            }
        };

        fetchOnlineUsers();
        const intervalId = setInterval(fetchOnlineUsers, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="users-online-container" title="Online Users">
            <h2>Habbos Online:</h2>
            {onlineUsers !== null ? (
                <p> {onlineUsers} </p>
            ) : (
                <p className="loading">Loading...</p>
            )}
            <div className="tooltip-content">
                <TimeDisplay />
            </div>
        </div>
    );
}

export default UsersOnline;
