import React, { useState } from 'react';
// Swiper React component
import { Swiper, SwiperSlide } from 'swiper/react';

// Swiper CSS Files
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import './furniSwiper.css';

// Required module
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';
import FurniSlide from './FurniSlide';



function FurniSwiper({ furnis }) {
    const [active, setActive] = useState(false);

    const handleToggleVideo = () => {
        setActive(!active);
    };

    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            navigation={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 35,
                stretch: 200,
                depth: 250,
                modifier: 1,
                slideShadows: true,
            }}
            // autoplay={{
            //     delay: 2500,
            //     disableOnInteraction: false,
            // }}
            modules={[EffectCoverflow, Navigation, Autoplay]}
            className="furniSwiper"
        >
            {furnis.map(furni => (
                <SwiperSlide key={furni._id}>
                    <FurniSlide
                        furni={furni}
                        active={active}
                        toggleVideo={handleToggleVideo}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default FurniSwiper

