import React, { useState, useEffect } from 'react';
import './furni.css';
import filterListData from '../data/filterListData';
import FurniCard from '../components/FurniCard';

function Furni({ furnis, reference }) {
    const [data, setData] = useState([]);

    const [filters, setFilters] = useState(filterListData);
    const handleFilterFurnis = category => {
        setFilters(
            filters.map(filter => {
                filter.active = false;
                if (filter.name === category) {
                    filter.active = true;
                }
                return filter;
            })
        );

        if (category === 'All') {
            setData(furnis)
            return;
        }

        setData(furnis.filter(furni => furni.category === category));
    };

    useEffect(() => {
        // Initialize data with furnis on mount or when furnis changes
        setData(furnis);
    }, [furnis]);

    const [text, setText] = useState('');

    const handleSearchFurni = e => {
        setData(furnis.filter(furni => furni.title.toLowerCase().includes(e.target.value.toLowerCase())
        )
    );
        setText(e.target.value);
    }

    return (
        <section id="furni" className='furni' ref={reference}>
            <div className="container-fluid mt-2">
                <div className="row">
                    <div className="col-lg-8 d-flex align-items-center justify-content-start">
                        <ul className="filters">
                            {filters.map(filter => (
                                <li
                                    key={filter._id}
                                    className={`${filter.active ? 'active' : undefined}`}
                                    onClick={() => handleFilterFurnis(filter.name)}
                                >
                                    {filter.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-4 d-flex align-items-center justify-content-end">
                        <div className="search">
                            <i className="bi bi-search"></i>
                            <input
                                type="text"
                                name="search"
                                value={text}
                                placeholder="Search"
                                onChange={handleSearchFurni}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {data.map(furni => (
                        <FurniCard key={furni._id} furni={furni} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Furni;