import React from 'react'
import { SwiperSlide } from 'swiper/react';

function FurniSlide({ furni, active, toggleVideo }) {
    return (
        <SwiperSlide >
            <div className="furniSlider">
                <img src={furni.img} alt="Habbo Furni" />
                <div className={`video ${active ? 'active' : 'undefined'}`}>
                    {/*  can replace with video asset here */}
                    <iframe
                        width="1280"
                        height="720"
                        src={furni.trailer}
                        title={furni.title}
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="content">
                    <h2>{furni.title}</h2>
                    <p>{furni.description}</p>
                    <div className="buttons">
                        <a href="#" className="orderBtn">
                            View Info
                        </a>
                        <a
                            href="#"
                            className={`playBtn ${active ? 'active' : ''}`}
                            onClick={toggleVideo}
                        >
                            <span className="pause">
                                <i className="bi bi-pause-fill"></i>
                            </span>
                            <span className="play">
                                <i className="bi bi-play-fill"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    )
}

export default FurniSlide