import React, { useContext } from 'react';
import './furniCard.css';
import FurniRating from './FurniRating';
import { AppContext } from '../App';

import clubsofa from '../images/icons/cs_icon.png';
import addBag from '../images/icons/add_icon.gif';
import like from '../images/icons/favorite_icon.gif';
import dislike from '../images/icons/heartblack_icon.gif';

function FurniCard({ furni }) {
    const { favorites, setFavorites, bag, setBag } = useContext(AppContext);

    const handleAddToFavorites = furni => {
        setFavorites([...favorites, furni])
    };

    const handleRemoveFromFavorites = furni => {
        setFavorites(favorites.filter(item => item._id !== furni._id));
    };

    const handleAddToBag = game => {
        // if(bag.includes(furni)) return;
        setBag([...bag, furni]);
    };

    return (
        <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="furniCard">
                <img src={furni.img} alt={furni.title} className="img-fluid" />
                <a href="#" className="like"
                    onClick={
                        favorites.includes(furni)
                            ? () => handleRemoveFromFavorites(furni)
                            : () => handleAddToFavorites(furni)
                    }
                >
                    <img src={
                        favorites.includes(furni)
                            ? like
                            : dislike}
                        alt="Favorites"
                    />
                </a>
                <div className="furniFeature">
                    <span className="furniType">{furni.category}</span>
                    <FurniRating rating={furni.rating} />
                </div>
                <div className="furniTitle mt-4">{furni.title}</div>
                <div className='furniInfo'>
                    <div className="furniDesc">{furni.description}</div>
                    <div className='furniRelease'>{furni.releasedate}</div>
                </div>
                <div className="furniPrice">
                    {
                        // This was changed check
                        furni.discount !== 0 && (
                            <>
                                <span className="discount">
                                    <i>
                                        <i class="bi bi-arrow-down-short"></i>{furni.discount * 100}%</i>
                                </span>
                                <span className="prevPrice">{furni.price.toFixed(2)}</span>
                            </>
                        )}
                    <span className="currentPrice">
                        <img src={clubsofa} alt="Club Sofa" className='cs-icon' />
                        {((1 - furni.discount) * furni.price).toFixed(2)}

                    </span>
                </div>
                <a href="#" className="addBag" onClick={() => handleAddToBag(furni)}>
                    {/* <i className="bi bi-bag-plus-fill"></i> */}
                    <img src={addBag} alt="Add Furni" className='add-icon' />
                </a>
            </div>
        </div>
    )
}

export default FurniCard;

