import React from 'react'
import './favorites.css';
import FurniCard from '../components/FurniCard';

function Favorites({ furnis, reference }) {
  return <section id="favorites" className='favorites' ref={reference}>
    <div className="container-fluid">
      <div className="row mb-3">
        <h1> My Favorites</h1>
      </div>
      <div className="row">
        {furnis.length === 0 ? (
          <h2> Your Favorites is empty </h2>
        ) : (
          furnis.map(furni => <FurniCard key={furni._id} furni={furni} />)
        )}
      </div>
    </div>

  </section>

}

export default Favorites;