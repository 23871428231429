import React, { useState, useEffect } from 'react';
import './header.css';
import defaultUserImg from '../images/habbo.gif';

const Header = ({ toggleActive }) => {
  const [userName, setUserName] = useState('Lango'); // Default username
  const [userData, setUserData] = useState(null);
  const [userNotFound, setUserNotFound] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const handleToggleMenu = (event) => {
    setIsSpinning(true);
    setTimeout(() => {
      setIsSpinning(false);
      setIsMenuActive(!isMenuActive);
    }, 300); // Duration of the spin animation
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const apiUrl = `https://origins.habbo.com/api/public/users?name=${userName}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        if (data && data.name) {
          setUserData(data);
          setUserNotFound(false);
        } else {
          setUserData(null);
          setUserNotFound(true);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUserData(null);
        setUserNotFound(true);
      }
    };

    fetchUserData();
  }, [userName]); // Include userName in the dependency array

  const handleInputChange = (event) => {
    setUserName(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // The user data will be fetched automatically due to the useEffect dependency on userName
  };

  return (
    <header className="container-fluid">
      <a href="#" className={`menu menuBtn ${isMenuActive ? 'active' : ''}`} onClick={(event) => {
        toggleActive();
        handleToggleMenu(event);
      }}>
        <div className='btnBg'>
        <span className={`close ${isSpinning ? 'spin' : ''}`}>
          <i className="bi bi-caret-right-fill"></i>
        </span>
        <span className={`open ${isSpinning ? 'spin' : ''}`}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
        </div>
      </a>
      <div className="userItems">
        <form onSubmit={handleFormSubmit} className="user-form">
            <input
              type="text"
              id="userNameInput"
              name="userName"
              value={userName}
              onChange={handleInputChange}
              placeholder="Enter username"
              className="user-input"
              autoComplete="username"
            />
{/* 
          <button type="submit" className="user-submit">
            Search
          </button> */}
        </form>

        <div className="avatar">
          <a href="#">
            <img
              src={userData && userData.figureString ? `https://www.habbo.com/habbo-imaging/avatarimage?figure=${userData.figureString}` : defaultUserImg}
              alt={userNotFound ? "Default User" : userName}
              className="user-img"
            />
          </a>
          <div className="user">
            <span>{userNotFound ? 'User Not Found' : userData ? userData.name : 'User Name'}</span>
            <a href="#">
              {/* View Profile */}
              </a>
          </div>

        </div>
        <a href="#" className="icon">
          <i className="bi bi-balloon-heart-fill"></i>
          <span className="like">0</span>
        </a>
        <a href="#" className="icon">
          <i className="bi bi-basket-fill"></i>
          <span className="bag">0</span>
        </a>
      </div>
    </header>
  );
};

export default Header;
