import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from '../App';
import './main.css';
import SideMenu from '../components/SideMenu';
import Header from './Header';
import PreventInspect from '../components/PreventInspect';

// Pages
import Home from './Home';
import Furni from './Furni';
import Favorites from './Favorites';
import Bag from './Bag';

function Main() {
    const { favorites, bag } = useContext(AppContext);
    const [active, setActive] = useState(false);
    const [furnis, setFurni] = useState([]);

    const homeRef = useRef();
    const furniRef = useRef();
    const favoritesRef = useRef();
    const bagRef = useRef();

    const sections = [
        {
            name: 'home',
            ref: homeRef,
            active: true,
        },
        {
            name: 'furni',
            ref: furniRef,
            active: true,
        },
        {
            name: 'favorites',
            ref: favoritesRef,
            active: true,
        },
        {
            name: 'bag',
            ref: bagRef,
            active: true,
        },
    ];

    const handleToggleActive = () => {
        setActive(!active);
    };

    const handleSectionActive = target => {
        sections.map(section => {
            section.ref.current.classList.remove('active');
            if (section.ref.current.id === target) {
                section.ref.current.classList.add('active');
            }
            return section;
        });
    };

    const fetchData = () => {
        fetch('https://originhabbo.com/api/furniData.json')
            .then(res => res.json())
            .then(data => {
                setFurni(data);
            })
            .catch(e => console.log(e.message));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <main>
            <PreventInspect />
            <SideMenu active={active} sectionActive={handleSectionActive} />
            <div className={`banner ${active ? 'active' : undefined}`}>
                <Header toggleActive={handleToggleActive} />
                <div className="container-fluid">
                    {furnis && furnis.length > 0 && (
                        <>
                            <Home furnis={furnis} reference={homeRef} />
                            <Furni furnis={furnis} reference={furniRef} />
                            <Favorites furnis={favorites} reference={favoritesRef} />
                            <Bag furnis={bag} reference={bagRef} />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
}

export default Main;