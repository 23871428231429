import React, { useState } from 'react'
import './sideMenu.css'
import navListData from '../data/navListData'
import NavListItem from './NavListItem'
import logoImg from '../images/ohbanner.png'
import UsersOnline from './UsersOnline'
// import socialListData from '../data/socialListData'

function SideMenu({ active, sectionActive }) {
    const [navData, setNavData] = useState(navListData);
    // const [socialData, setSocialData] = useState(socialListData)

    const handleNavOnClick = (id, target) => {
        const newNavData = navData.map(nav => {
            console.log(id)
            nav.active = false;
            if (nav._id === id) nav.active = true;
            return nav
        });
        setNavData(newNavData);
        sectionActive(target);
    };

    return (
        <div className={`sideMenu ${active ? `active` : undefined}`}>
            <a href='#' className='logo'>
                <img src={logoImg} alt="logo" className="logo-image" />
                {/* <i className="bi bi-joystick"></i> */}
                {/* <span className='brand'>
                    Rare
                    </span> */}
            </a>

            <ul className="nav">
                {navData.map(item => (
                    <NavListItem key={item._id}
                        item={item}
                        navOnClick={handleNavOnClick} />
                ))}
            </ul>
            {/* <ul className="social">
                {socialData.map(item => (
                    <NavListItem key={item._id} item={item} />
                ))}
            </ul> */}
            {/* SOCIAL MEDIA BELOW */}
            {/* <ul className="social">
                <li>
                    <a href="#">
                        <i className='bi bi-meta'></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bi bi-twitter-x'></i>
                    </a>
                </li>
                <li>
                    <a href="#">
                        <i className='bi bi-youtube'></i>
                    </a>
                </li>
                <li>
                    <a href="#" className="share">
                        <i className='bi bi-share'></i>
                    </a>
                </li>
            </ul> */}
            <div className='api-stats'>
                <UsersOnline />
            </div>
        </div>

    );


}

export default SideMenu;