const navListData = [
    {
        _id: 1,
        target: 'home',
        name: 'Home',
        icon: 'bi-house',
        active: true,
    },
    {
        _id: 2,
        target: 'furni',
        name: 'Furni',
        icon: 'bi-box-seam',
        active: false,
    },
    {
        _id: 3,
        target: 'favorites',
        name: 'Favorites',
        icon: 'bi-heart',
        active: false,
    },
    {
        _id: 4,
        target: 'bag',
        name: 'Bag',
        icon: 'bi-basket',
        active: false,
    },
];

export default navListData;