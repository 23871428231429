import React, { useState } from 'react';
import './home.css';
import FurniSwiper from '../components/FurniSwiper';
import FurniCard from '../components/FurniCard';
import banner from '../images/Banner_LFM.gif';
import clubsofa from '../images/icons/cs_icon.png';

function Home({ furnis, reference }) {
  const currentYear = new Date().getFullYear();
  const [selectedFurni1, setSelectedFurni1] = useState(null);
  const [selectedFurni2, setSelectedFurni2] = useState(null);
  const [conversionResult, setConversionResult] = useState(null);

  // Sort furnis array
  const sortedFurnis = furnis.slice().sort((a, b) => {
    // Ensure Club Sofa (_id === 1) always comes first
    if (a._id === 1) return -1;
    if (b._id === 1) return 1;
    // Sort by price descending for other items
    return b.price - a.price;
  });

  // Function to handle conversion calculation
  const handleConversion = () => {
    if (!selectedFurni1 || !selectedFurni2) {
      alert('Please select items for conversion');
      return;
    }

    const furni1Price = selectedFurni1.price;
    const furni2Price = selectedFurni2.price;

    // Perform your conversion calculation here
    const result = furni1Price / furni2Price;

    setConversionResult(result);
  };

  return (
    <section id="home" className="home active" ref={reference}>
      <div className="container-fluid">
        <div className="row">
          <FurniSwiper furnis={furnis} />
        </div>

        {/* Header Section */}
        <div className="row mb-4 mt-4">
          <div className="col-lg-6">
            <h2 className="sectionTitle">Furni Values</h2>
            <span className="source">
              Last updated 2 hours ago
            </span>
          </div>
          <div className="col-lg-6 d-flex justify-content-end align-items-center">
            <a href="#" className='viewMore'>
              View more furni  <i className="bi bi-caret-right-fill"></i>
            </a>
          </div>
        </div>

        {/* Cards */}
        <div className="row">
          {sortedFurnis.slice(0, 4).map(furni => (
            <FurniCard key={furni._id} furni={furni} />
          ))}
        </div>


        {/* Conversion Section */}
        <div className="row mb-4 mt-4">
          <div className="col-lg-6">
        <h2 className="sectionTitle">FURNI Converter</h2>
        <span className="source">Work in progress</span>
        </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <div className="col-lg-8 conversion-group">
            <div className="d-flex flex-wrap justify-content-between align-items-start">
              <div className="form-group mb-3">
                <label className="label-furni1" htmlFor="furni1">Furni 1 <i className="bi bi-caret-down-fill"></i></label>
                <select
                  id="furni1"
                  className="form-control"
                  value={selectedFurni1 ? selectedFurni1._id : ''}
                  onChange={(e) => {
                    const selectedId = parseInt(e.target.value);
                    const selected = furnis.find(item => item._id === selectedId);
                    setSelectedFurni1(selected);
                  }}
                >
                  <option value="">Select furni...</option>
                  {furnis.map(furni => (
                    <option key={furni._id} value={furni._id}>{furni.title}</option>
                  ))}
                </select>
              </div>

              {/* <span className="text-white"> x </span> */}

              <div className="form-group mb-3">
                <label className="label-furni2" htmlFor="furni2">Furni 2 <i className="bi bi-caret-down-fill"></i></label>
                <select
                  id="furni2"
                  className="form-control"
                  value={selectedFurni2 ? selectedFurni2._id : ''}
                  onChange={(e) => {
                    const selectedId = parseInt(e.target.value);
                    const selected = furnis.find(item => item._id === selectedId);
                    setSelectedFurni2(selected);
                  }}
                >

                  <option value="">Select furni...</option>
                  {furnis.map(furni => (
                    <option key={furni._id} value={furni._id}>{furni.title}</option>
                  ))}
                </select>
              </div>

              <button className="btn btn-primary mb-3" onClick={handleConversion}>Calculate Conversion</button>
            </div>

            {conversionResult !== null && (
              <div className="conversion-result d-flex justify-content-center align-items-center mt-3">
                <span>{conversionResult.toFixed(2)}</span>
                <img src={clubsofa} alt="Club Sofa" className='cs-icon ml-2' />
              </div>
            )}
          </div>
        </div>

        {/* Banner Section */}
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <img src={banner} alt="banner" className="banner_adv img-fluid mb-4 mt-4" style={{ mmaxWidth: '100%', height: 'auto', maxHeight: '200px' }} />
          </div>
        </div>

        {/* Footer */}
        <div className="footer">
          <div className='row'>
            <span className="disclaimer">
              &copy; {currentYear} OriginHabbo is an independent entity and is not affiliated with, endorsed, sponsored, or specifically approved by Sulake or its affiliates. OriginHabbo may use the trademarks and other intellectual property of Habbo, which is permitted under Habbo Fan Site Policy. Do not use the same password you use for Habbo anywhere else but Habbo. *All Data is sourced from multiple resources to ensure fair and accurate values.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
