import React, { useEffect } from 'react';

const PreventInspect = () => {
    useEffect(() => {
        const preventInspect = (event) => {
            // Prevent right-click context menu
            if (event.button === 2) {
                event.preventDefault();
                // console.log('Right click disabled.');
            }
        };

        const preventKeys = (event) => {
            // Prevent common key combinations
            if ((event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
                (event.ctrlKey && event.keyCode === 85) || // Ctrl+U
                (event.ctrlKey && event.shiftKey && event.keyCode === 67) || // Ctrl+Shift+C
                (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
                (event.ctrlKey && event.shiftKey && event.keyCode === 75) || // Ctrl+Shift+K
                (event.metaKey && event.altKey && event.keyCode === 73)) { // Mac: Cmd+Option+I
                event.preventDefault();
                // console.log('Keyboard shortcuts disabled.');
            }
        };

        document.addEventListener('contextmenu', preventInspect);
        document.addEventListener('keydown', preventKeys);

        return () => {
            document.removeEventListener('contextmenu', preventInspect);
            document.removeEventListener('keydown', preventKeys);
        };
    }, []);

    return null; // This component doesn't render any UI
};

export default PreventInspect;
